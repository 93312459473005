@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  height: 100%;
  width: 100%;
}

.mainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
  font-family: "Oswald", sans-serif;
  background-image: url("../public/images/169399457_740628879955808_1114024967334443253_n-1024x681.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mainSection > h1 {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 9rem;
  color: #0872bdec;
  text-align: center;

  -webkit-text-stroke: 2px #0d446c7e;
}

.animationText {
  width: 100%;
  height: min-content;
  line-height: 2rem;
  font-size: 3rem;
  color: rgb(0, 0, 0);
  text-align: center;
}

.carta {
  text-align: center;
  text-decoration: none;
  margin: 1rem;
  width: min-content;
  color: white;
  font-weight: bold;
  background-color: rgb(11, 147, 226);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.menuSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.imagenCarta {
  margin-top: 2rem;
  width: 70%;
}

@media (max-width: 600px) {
  .mainSection > h1 {
    font-size: 5rem;
    top: 4.1rem;
  }
  .imagenCarta {
    margin-top: 2rem;
    width: 90vw;
  }

  .animationText {
    font-weight: 700;
    font-size: 2rem;
    top: 10rem;
  }
}
